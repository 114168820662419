export const PRIMARY_BACKGROUND_COLOR = '#323444';
export const SECONDARY_BACKGROUND_COLOR = '#272333';

export const BRIGHT_FONT_COLOR = '#FFF';
export const PRIMARY_FONT_COLOR = '#F4F4F9';
export const SECONDARY_FONT_COLOR = '#B7BBCF';
export const EMPHASIS_FONT_COLOR = '#FCBF58';

export const PRIMARY_SELECTION_COLOR = '#FEAE28';
export const SECONDARY_SELECTION_COLOR = '#F4D35E';
export const UNSELECTED_COLOR = '#424454';

export const PRIMARY_ATTENTION_COLOR = '#60992D';
export const SECONDARY_ATTENTION_COLOR = '#6EA240';

export const PRIMARY_LINKEDIN_COLOR = '#0a66c2';
export const SECONDARY_LINKEDIN_COLOR = '#2284e6';

// export const NAVBAR_COLOR = '#D69F7E';
